
import * as Components from "./Shekarchiwest"

export default function getCustomComponent () {
  return Components
}

export function getShopId () {
  return "shekarchiwest"
}

